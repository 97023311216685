import React from 'react'
import Styled from 'styled-components'
import Wrapper from '../components/wrapper'
import SEO from '../components/seo'
import Body from '../components/body'
import Header from '../components/header'
import Footer from '../components/footer'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

const Container = Styled.div`
    text-align: center;
    padding: 15px;
`
const Link = Styled(AniLink)`
    display: inline-block;
    color: #7BC3DA;
    font-size: 18px;
    border: 2px solid #7BC3DA;
    border-radius: 15px;
    padding: 10px;
    margin: 25px auto;
    text-decoration: none;
    cursor: pointer;
    transition: all .25s ease;

    &:hover, &:focus {
        background: #7BC3DA;
        color: #fff;
    }
`

const NotFoundPage = () => (
    <Wrapper>
      <SEO title="404: Not found" />
      <Header />
      <Body>
        <Container>
            <h1>404: Page Not Found.</h1>
            <Link cover direction='right' bg='#7BC3DA' to='/'>Return Home</Link>
        </Container>
      </Body>
      <Footer />
    </Wrapper>
)

export default NotFoundPage
